import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

const styles = theme => ({
  extLink: {
    color: '#094ACB',
    textDecoration: 'underline',
    '&:hover': {
      color: '#094ACB',
      textDecoration: 'underline',
    },
    '&:visited': {
      color: '#8517D9',
      textDecoration: 'underline',
    },
  },

  extLinkRecolor: {
    color: '#AAAAAA',
    '&:hover': {
      color: '#AAAAAA',
    },
    '&:visited': {
      color: '#AAAAAA',
    },
  },

});

const ExtLink = ({ classes, className, href, title, recolor, children }) => (
  <OutboundLink
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    title={title}
    className={classNames(
      classes.extLink,
      recolor ? classes.extLinkRecolor : undefined,
      className
    )}
  >
    {children}
  </OutboundLink>
);

ExtLink.propTypes = {
  href: PropTypes.string.isRequired,
  title: PropTypes.string,
  recolor: PropTypes.boolean,
};

ExtLink.defaultProps = {
  recolor: false,
  title: '',
};

export default withStyles(styles)(ExtLink);
