import React, { Component } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogContent } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

//import ExtLink from '../ExtLink'
import Credits from '../Credits';
import PrivacyPolicy from '../PrivacyPolicy';

const styles = theme => ({
  text: {
    color: '#fff',
    backgroundColor: '#03215d',
    fontSize: '0.7em',
    textAlign: 'center',
    padding: '15px',
  },
  link: {
    color: '#fff',
    '&:hover': {
      color: '#fff',
    },
    '&:visited': {
      color: '#fff',
    },
  },
  dialogXs: {
    margin: '8px',
  },
  dialogDismiss: {
    position: 'absolute',
    top: '8px',
    right: '16px',
    fontSize: '32px',
    color: '#333333',
    '&:hover': {
      color: '#000000',
    },
  },
  dialogContent: {
    fontSize: '14px',
    textAlign: 'left',
    color: '#191919',
    lineHeight: '22px',
    [theme.breakpoints.down('sm')]: {
      margin: '0px',
    },
  },
});

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: {
        privacyPolicy: false,
        credits: false,
      },
    };

  }

  /**
   * Set Dialog Open State
   * @param {object} newDialogState in format (dialogName: Boolean)
   */
  setDialogState(newDialogState) {
    this.setState(prevState => ({
      open: {
        ...prevState.open,
        ...newDialogState,
      }
    }));
  }

  render() {
    const { classes, className, company, dataController } = this.props;

    return (
      <div>
        <div className={classNames(classes.text, className)}>
          A TechForUK project. © Forward Democracy Ltd 2018-2019. All Rights reserved.{' '}
          <a
            onClick={() => {this.setDialogState({credits: true})}}
            className={classNames(classes.link, className)}
            href="#credits"
          >
            Credits
          </a>&nbsp;|&nbsp;
          <a
            onClick={() => {this.setDialogState({privacyPolicy: true})}}
            className={classNames(classes.link, className)}
            href="#privacy-policy"
          >
            Privacy Policy
          </a>
        </div>

        <Dialog
          id="credits"
          open={this.state.open.credits}
          onClose={() => {this.setDialogState({credits: false})}}
          classes={{
            paperWidthXs: classNames(classes.dialogXs, className),
            paperWidthSm: classNames(classes.dialogXs, className),
          }}
        >
          <FontAwesomeIcon
            icon={faTimes}
            onClick={() => {this.setDialogState({credits: false})}}
            className={classNames(classes.dialogDismiss, className)}
          />
          <DialogContent
            className={classNames(classes.dialogContent, className)}
          >
            <Credits />
          </DialogContent>
        </Dialog>

        <Dialog
          id="privacy-policy"
          open={this.state.open.privacyPolicy}
          onClose={() => {this.setDialogState({privacyPolicy: false})}}
          classes={{
            paperWidthXs: classNames(classes.dialogXs, className),
            paperWidthSm: classNames(classes.dialogXs, className),
          }}
        >
          <FontAwesomeIcon
            icon={faTimes}
            onClick={() => {this.setDialogState({privacyPolicy: false})}}
            className={classNames(classes.dialogDismiss, className)}
          />
          <DialogContent
            className={classNames(classes.dialogContent, className)}
          >
            <PrivacyPolicy />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

Footer.propTypes = {
  name: PropTypes.string,
  message: PropTypes.string,
};

Footer.defaultProps = {
  company: 'Forward Democracy Limited',
  dataController:
    'Studio Microclimate, Great Northern Road, Cambridge, United Kingdom, CB1 2FX, admin@forwarddemocracy.com',
};

export default withStyles(styles)(Footer);
