import React from 'react';
// import PropTypes from 'prop-types';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import Footer from '../Footer';

import { ReactComponent as BasketIcon } from '../../assets/title-basket.svg';
import { ReactComponent as HeartIcon } from '../../assets/title-heart.svg';
import { ReactComponent as UKIcon } from '../../assets/title-uk.svg';

import './layout.scss';

const styles = theme => ({
  icons: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '56px',
    marginTop: '30px',
  },
  icon: {
    margin: '10px',
  },
  title: {
    fontFamily: '"Roboto Condensed", Helvetica, sans-serif',
    fontSize: '46px',
    color: '#FFFFFF',
    letterSpacing: '0.19px',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: '700',
    margin: '0 auto 20px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '32px',
    },
    '& span': {
      fontWeight: '300',
    }
  },
  gridArea: {
    minHeight: '100vh',
  },
  contentArea: {
    flex: '2 1 auto',
  },
  footerArea: {
    flex: '0 1 0px',
  },
  container: {
    maxWidth: '960px',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: '8px',
    paddingRight: '8px',
  },
});

class Layout extends React.Component {
  render() {
    const { classes, className, children } = this.props;
    return (
      <div>
        <Grid container justify="flex-start" direction="column" className={classNames(classes.gridArea, className)}>
          <Grid item xs>
            <Grid container justify="center">
              <Grid item xs>
                <div className={classNames(classes.icons, classes.container, className)}>
                  <BasketIcon className={classNames(classes.icon, className)} />
                  <HeartIcon className={classNames(classes.icon, className)} />
                  <UKIcon className={classNames(classes.icon, className)} />
                </div>
                <h1 className={classNames(classes.title, className)}>
                  Compare <span>the</span> Brexit
                </h1>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs className={classNames(classes.contentArea, className)}>
            <div className={classNames(classes.container, className)}>
              {children}
            </div>
          </Grid>
          <Grid item xs className={classNames(classes.footerArea, className)}>
            <Footer />
          </Grid>
        </Grid>
      </div>
    );
  }
}

Layout.propTypes = {};

export default withStyles(styles)(Layout);
