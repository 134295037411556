import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import get from 'lodash/get';

import { siteMetadata } from '../../../gatsby-config';

const Meta = ({ title, description, location, shareImage }) => {
  const siteTitle = get(siteMetadata, 'title');
  const pageTitle = title ? `${title} | ${siteTitle}` : siteTitle;
  return (
    <Helmet
      title={pageTitle}
      meta={[
        { name: 'description', content: description },
        { name: 'keywords', content: get(siteMetadata, 'keywords') },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:site', content: `@${get(siteMetadata, 'twitter')}` },
        { name: 'twitter:title', content: siteTitle },
        {
          name: 'twitter:description',
          content: description,
        },
        {
          name: 'twitter:image',
          content: `${get(siteMetadata, 'siteUrl')}/img/share/share-${shareImage}.png`,
        },
        { name: 'twitter:domain', content: get(siteMetadata, 'siteUrl') },
        { name: 'twitter:creator', content: get(siteMetadata, 'twitter') },

        { property: 'og:site_name', content: siteTitle },
        { property: 'og:title', content: get(siteMetadata, 'title') },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:description',
          content: description,
        },
        {
          property: 'og:url',
          content: get(siteMetadata, 'siteUrl') + (location ? location.pathname : '/'),
        },
        {
          property: 'og:image',
          content: `${get(siteMetadata, 'siteUrl')}/img/share/share-${shareImage}.png`,
        },
      ]}
    >
      <link rel="preconnect" href="https://www.iubenda.com" />
    </Helmet>
  );
};

Meta.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  location: PropTypes.object.isRequired,
  shareImage: PropTypes.string,
};

Meta.defaultProps = {
  title: null,
  description: get(siteMetadata, 'description'),
  shareImage: 'default',
};

export default Meta;
