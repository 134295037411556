import React from 'react';

import ExtLink from '../ExtLink';

const Credits = () => (
  <div>
    <h2>Credits</h2>
    <p>
      Icons made by{' '},
      <ExtLink href="https://www.flaticon.com/authors/kiranshastry">
        Kiranshastry
      </ExtLink>
      , <ExtLink href="https://www.flaticon.com/authors/roundicons">Roundicons</ExtLink>
      , <ExtLink href="https://www.flaticon.com/authors/eucalyp">Eucalyp</ExtLink>
      , <ExtLink href="https://www.flaticon.com/authors/freepik">Freepik</ExtLink>
      ,{' '}
      <ExtLink href="https://www.flaticon.com/authors/pixel-buddha">
        Pixel Buddha
      </ExtLink>
      ,{' '}
      <ExtLink href="https://www.flaticon.com/authors/pixel-perfect">
        Pixel perfect
      </ExtLink>{' '}
      <ExtLink href="https://www.flaticon.com/authors/geotatah">
        geotatah
      </ExtLink>{' '}
      <ExtLink href="https://www.flaticon.com/authors/monkik">
        monkik
      </ExtLink>{' '}
      <ExtLink href="https://www.flaticon.com/authors/good-ware">
        Good Ware
      </ExtLink>{' '}
      and{' '}
      <ExtLink href="https://www.flaticon.com/authors/pause08">
        Pause08
      </ExtLink>{' '}
      <ExtLink href="https://www.flaticon.com/authors/mavadee">
        mavadee
      </ExtLink>{' '}
      from <ExtLink href="https://www.flaticon.com">www.flaticon.com</ExtLink>
    </p>
    <p>
      A TechForUK project led by <ExtLink href="https://www.linkedin.com/in/beckysnowden/">Becky</ExtLink>,{' '}
      with <ExtLink href="https://www.linkedin.com/in/tomdegrunwald/">Tom</ExtLink>.{' '}
      Site design by <ExtLink href="https://www.linkedin.com/in/meganellis/">Megan</ExtLink>.{' '}
      Copy by <ExtLink href="https://www.linkedin.com/in/beckysnowden/">Becky</ExtLink>.{' '}
      Original build by Makosoft.{' '},
      Additional development by <ExtLink href="https://andybroomfield.com">Andy</ExtLink>.{' '}
      Maintained by volunteers.
    </p>
  </div>
);

export default Credits;
